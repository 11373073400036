import '~/styles/main.scss';
import { createApp } from 'vue';
import CountryFlag from 'vue-country-flag-next';
import App from '~/App.vue';
import {
  head,
  i18n,
  opaleComponents,
  opaleForm,
  persistedPinia,
  vuetify
} from '~/modules';
import { router } from '~/router/router';
import { initializeSentry, opaleApi } from '~/utils';

const app = createApp(App);

app.use(head)
  .use(i18n)
  .use(opaleApi)
  .use(persistedPinia)
  .use(router)
  .use(opaleForm)
  .use(opaleComponents)
  .use(vuetify)
  .use(CountryFlag);

initializeSentry(app, router);

app.mount('#app');
