import { setupLayouts } from 'virtual:generated-layouts';
import { createRouter, createWebHistory } from 'vue-router';
import generatedRoutes from '~pages';

export const router = createRouter({
  history: createWebHistory(),
  routes: setupLayouts(generatedRoutes),
  scrollBehavior: (to, from, savedPosition) => (savedPosition) ? savedPosition : { el: '#app' },
});

