import { createI18n } from 'vue-i18n';
import { messages, numberFormats } from '~/locales';

export const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'fr',
  fallbackLocale: 'fr',
  messages,
  numberFormats
});
