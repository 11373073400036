import { fr as validation }       from 'yup-locales';

export { default as _app }        from './_app.json';
export { default as _dialogs }    from './_dialogs.json';
export { default as _enums }      from './_enums.json';
export { default as _layouts }    from './_layouts.json';
export { default as _components } from './_components.json';

export { default as documents }   from './documents.json';
export { default as subscribe }   from './subscribe.json';
export { default as login }       from './login.json';

export const _validation = {
  ...validation
};
