import { fr as validation }         from 'yup-locales';

export { default as _app }          from './_app.json';
export { default as _components }   from './_components.json';
export { default as _dialogs }      from './_dialogs.json';
export { default as _enums }        from './_enums.json';

export { default as dashboard }     from './dashboard.json';
export { default as documents }     from './documents.json';
export { default as fund }          from './fund.json';
export { default as funds }         from './funds.json';
export { default as login }         from './login.json';
export { default as logout }        from './logout.json';
export { default as notFound }      from './not-found.json';
export { default as profile }       from './profile.json';
export { default as profileStart }  from './profileStart.json';
export { default as settings }      from './settings.json';
export { default as subscribe }     from './subscribe.json';
export { default as subscriptions } from './subscriptions.json';

export const _validation = {
  ...validation
};
