import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAuth } from '~/stores';

export interface MenuItem {
  text: string,
  to: { name?: string },
  variant?: 'text' | 'flat' | 'outlined' | 'plain'
  icon?: string
}

export const useItems = () => {
  const { t } = useI18n();
  const authStore = useAuth();

  const userItems = computed<Array<MenuItem>>(() => (authStore.authenticated) ? [
    {
      text: t('_app.header.userMenu.settings'),
      to: { name: 'settings' },
      icon: 'fa-gear'
    },
    {
      text: t('_app.header.userMenu.logout'),
      to: { name: 'logout' },
      icon: 'fa-right-from-bracket'
    },
  ] : []);

  const actionItems = computed<Array<MenuItem>>(() => (authStore.authenticated) ? [
    { text: t('_app.header.actionMenu.dashboard'),     to: { name: 'dashboard'      }, icon: 'fa-chart-pie' },
    { text: t('_app.header.actionMenu.subscriptions'), to: { name: 'subscriptions'  }, icon: 'fa-chart-line' },
    { text: t('_app.header.actionMenu.funds'),         to: { name: 'funds'          }, icon: 'fa-shapes' },
    { text: t('_app.header.actionMenu.documents'),     to: { name: 'documents'      }, icon: 'fa-file-arrow-down' }
  ] : []);

  const mobileActionItems = computed<Array<MenuItem>>(() => (authStore.authenticated) ? [
    { text: t('_app.header.mobileActionMenu.dashboard'),     to: { name: 'dashboard'     }, icon: 'fa-chart-pie' },
    { text: t('_app.header.mobileActionMenu.subscriptions'), to: { name: 'subscriptions' }, icon: 'fa-chart-line' },
    { text: t('_app.header.mobileActionMenu.funds'),         to: { name: 'funds'         }, icon: 'fa-shapes' },
    { text: t('_app.header.mobileActionMenu.documents'),     to: { name: 'documents'     }, icon: 'fa-file-arrow-down' }
  ] : []);

  return { actionItems, mobileActionItems, userItems };
};
