import type { Uuid } from '@opalecapital/api-client';
import { Fund, useApi } from '@opalecapital/api-client';
import { camelCase } from 'lodash';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export const useFund = (payload?: Fund) => {
  const { apiClient } = useApi();
  const { t, d, n } = useI18n();

  const fund = ref(payload); // tovalue

  const getTags = ((
    selectedTags: Array<string> = ['investmentType', 'businessSectors', 'sfdrType']
  ) => {
    const tagsArray: Array<{ text: string; color: string, rounded?: boolean }> = [];
    if (fund.value === undefined) {
      return tagsArray;
    }

    if (fund.value.investmentType && selectedTags.includes('investmentType')) {
      tagsArray.push({
        text: t(`_enums.investmentType.${camelCase(fund.value.investmentType)}`),
        color : '',
      });
    }

    if (fund.value.businessSectors && selectedTags.includes('businessSectors')) {
      for (const sector of fund.value.businessSectors) {
        tagsArray.push({
          text: t(`_enums.businessSector.${camelCase(sector)}`),
          color : 'secondary'
        });
      }
    }

    if (fund.value.sfdrType === Fund.SFDRType.Article8 && selectedTags.includes('sfdrType')) {
      tagsArray.push({
        text: 'ARTICLE 8',
        color: 'success',
        rounded: true
      });
    }

    if (fund.value.sfdrType === Fund.SFDRType.Article9 && selectedTags.includes('sfdrType')) {
      tagsArray.push({
        text: 'ARTICLE 9',
        color: 'success',
        rounded: true
      });
    }

    return tagsArray;
  });

  const fetchBannerImage = () => fund.value?.id ? apiClient.fund.getBannerUrl(fund.value?.id) : null;

  const avatarUrl = () => {
    return payload?.avatar?.id ? apiClient.fund.getAvatarUrl(payload.id as Uuid) : null;
  };

  const dueDates = () => {
    const endingDate = () => {
      if (!fund.value || !fund.value.endingDate) {
        return null;
      }
      return d(fund.value.endingDate);
    };
    const nextClosingDate = () => {
      if (!fund.value || !fund.value.nextClosingDate) {
        return t('fund.dueDates.noDateInfo');
      }
      return d(fund.value.nextClosingDate);
    };

    return {
      nextClosingDate: nextClosingDate(),
      endingDate: endingDate(),
    };
  };

  const getKeyFigures = (
    selectedFigures: Array<string> = ['targetNetIRR', 'minimalSubscriptionAmount', 'tikehauInvestment']
  ) => {
    const keyFiguresArray: Array<{ label: string; value: string }> = [];
    if (!fund.value) {
      return null;
    }

    if (fund.value.targetNetIrr && selectedFigures.includes('targetNetIRR')) {
      keyFiguresArray.push({
        label: t('fund.keyFigures.targetNetIRR'),
        value: fund.value.targetNetIrr + '%'
      });
    }

    if (fund.value.minimalSubscriptionAmount && selectedFigures.includes('minimalSubscriptionAmount')) {
      keyFiguresArray.push({
        label: t('fund.keyFigures.minimalSubscriptionAmount'),
        value: n(fund.value.minimalSubscriptionAmount, 'currency')
      });
    }

    if (fund.value.tikehauInvestment && selectedFigures.includes('tikehauInvestment')) {
      keyFiguresArray.push({
        label: t('fund.keyFigures.tikehauInvestment'),
        value: fund.value.tikehauInvestment
      });
    }

    return keyFiguresArray;
  };

  // FUND CARD
  const iconForBusinessSector = {
    [Fund.BusinessSector.AgriFood]: 'fa:fas fa-wheat-awn',
    [Fund.BusinessSector.Tech]: 'fa:fas fa-microchip',
    [Fund.BusinessSector.HealthCare]: 'fa:fas fa-dna',
    [Fund.BusinessSector.ConsumerGoods]: 'fa:fas fa-basket-shopping',
    [Fund.BusinessSector.EnergyAndUtilities]: 'fa:fas fa-bolt',
    [Fund.BusinessSector.Industrials]: 'fa:fas fa-industry',
    [Fund.BusinessSector.Global]: 'fa:fas fa-globe'
  };

  const iconsForBusinessSectors = computed(() => {
    if (fund.value?.businessSectors) {
      return fund.value.businessSectors.map((sector) => {
        return {
          icon: iconForBusinessSector[sector],
          color: 'white',
          tooltip: t(`_enums.businessSector.${camelCase(sector)}`)
        };
      });
    }

    return [{
      icon: iconForBusinessSector[Fund.BusinessSector.Global],
      color: 'secondary',
      tooltip: t('funds.card.icons.global')
    }];
  });

  return {
    iconsForBusinessSectors,
    getTags,
    avatarUrl,
    fetchBannerImage,
    dueDates,
    getKeyFigures
  };
};
