import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';

export const useSettings = defineStore(
  'settings',
  () => {
    const locale = ref() as Ref<string>;
    const shortLocale = computed(() => locale.value?.split('-')[0]);
    return { locale, shortLocale };
  },
  {
    persist: true
  }
);
