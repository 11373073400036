<script lang="ts" setup>
import { kebabCase } from 'lodash';
import { useRoute } from 'vue-router';
import opaleLogo from '~/assets/opale-blue.png';
import { AppFooter, LoadingContainer, TitleContainer } from '~/components';
import { useAuth, useLayout, useSettings } from '~/stores';
import { useItems } from '.';

const route = useRoute();
const { actionItems, mobileActionItems, userItems } = useItems();
const authStore = useAuth();
const layoutStore = useLayout();
const settingsStore = useSettings();
</script>

<template>
  <o-app-bar
    :logo="opaleLogo"
    :logo-to="{ name: 'dashboard' }"
    :user-menu-items="userItems"
    :user-email-address="authStore.authenticatedUser?.emailAddress"
    :user-fullname="authStore.authenticatedUser?.fullname"
    :short-locale="settingsStore.shortLocale"
  />
  <o-mobile-navigation :nav-items="mobileActionItems" />
  <o-side-bar :action-items="actionItems" />
  <v-main :id="kebabCase(route.name?.toString())">
    <title-container :title="layoutStore.title" />
    <loading-container
      v-if="layoutStore.loading"
      :indeterminate="layoutStore.settings.indeterminate"
      :color="layoutStore.settings.color"
      type="linear"
      vertical-align="top"
    />
    <router-view />
  </v-main>
  <app-footer />
</template>
