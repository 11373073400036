import { createOpaleApiClient } from '@opalecapital/api-client';

const {
  VITE_OAUTH_CLIENT_ID: clientId,
  VITE_OAUTH_CLIENT_SECRET: clientSecret,
  VITE_OAUTH_CLIENT_SCOPE: clientScope,
  VITE_OPALE_API_URL: apiUrl,
} = import.meta.env;

export const opaleApi = createOpaleApiClient({ apiUrl, clientId, clientSecret, clientScope });
