import type { NumberOptions } from 'vue-i18n';
import * as enGB from './en-GB';
import * as fr from './fr';

const defaultNumberFormat: Record<string, NumberOptions> = {
  currency: {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
    currencyDisplay: 'narrowSymbol'
  },
  multiple: {
    style: 'decimal',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
    minimumSignificantDigits: 2
  },
  percent: {
    style: 'percent',
    useGrouping: true
  }
};

export const messages = {
  'en-GB': enGB,
  fr
};

export const numberFormats = {
  'en-GB':  { ...defaultNumberFormat },
  fr:       { ...defaultNumberFormat }
};
