<script lang="ts" setup>
import { useDialogs } from '@opalecapital/ui/dialogs/composables';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { LoadingContainer, SnackbarManager } from '~/components';
import { Http401ErrorDialog } from '~/dialogs';
import { initGuards } from '~/router/guards';
import { useAuth } from '~/stores';
import {
  initEvents,
  initHead,
  initInterceptors,
  initLocale,
  initTags
} from '~/utils';

declare global {
  interface Window {
    _hsq: [unknown];
    _hsp: [unknown];
  }
}

const dialogs = useDialogs({ Http401ErrorDialog });
const { locale } = useI18n();

const initialized = ref(false);
const router = useRouter();
const authStore = useAuth();

//init
initGuards().then(() => initialized.value = true);
initEvents();
initInterceptors();
initHead();
initLocale();
initTags();

router.afterEach((to) => {
  sendToAnalytics(to.fullPath);
});

const sendToAnalytics = (fullPath: unknown): void => {
  console.log('Sending analytics for path:', fullPath);
  const _hsq = window._hsq = window._hsq || [];

  _hsq.push(['setPath', fullPath]);
  _hsq.push(['trackPageView']);
  _hsq.push(['identify', {
    email: authStore.authenticatedUser?.emailAddress,
  }]);
};
</script>

<template>
  <v-app
    :key="`app.${locale}`"
    theme="opale-light"
  >
    <http401-error-dialog
      v-model="dialogs.http401Error.displayed.value"
      @close="dialogs.http401Error.hide()"
    />
    <snackbar-manager />

    <router-view v-if="initialized" />
    <v-main v-else>
      <loading-container
        type="circular"
        vertical-align="center"
      />
    </v-main>
  </v-app>
</template>
