<script lang="ts" setup>
import { Fund, InvestorProfile } from '@opalecapital/api-client';
import { computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useFund } from '~/composables';

const props = withDefaults(defineProps<{
  fund: Fund;
  investorProfileStatus?: InvestorProfile.Status;
}>(), {
  investorProfileStatus: InvestorProfile.Status.New
});

const { fund, investorProfileStatus } = toRefs(props);

const { d } = useI18n();
const { iconsForBusinessSectors, getKeyFigures, getTags, avatarUrl } = useFund(fund.value);

const isFundLocked = computed(() => investorProfileStatus.value !== InvestorProfile.Status.Validated);
const isFundDisabled = computed(() => fund.value?.subscriptionPeriodStatus === Fund.SubscriptionPeriodStatus.Closed);
const isProfileUnderReview = computed(() => investorProfileStatus.value === InvestorProfile.Status.UnderReview);
const fundCardTags = getTags(['investmentType', 'sfdrType']);
const fundCardKeyFigures = getKeyFigures(['targetNetIRR', 'tikehauInvestment']);
const beginingDate = computed(() => {
  if (!fund.value || !fund.value.beginningDate) {
    return;
  }
  return d(fund.value.beginningDate) ;
});
const endingDate = computed(() => {
  if (!fund.value || !fund.value.endingDate) {
    return;
  }
  return d(fund.value.endingDate) ;
});

const hasToCompleteProfile = computed(() =>
  ![InvestorProfile.Status.Validated, InvestorProfile.Status.UnderReview].includes(investorProfileStatus.value)
);

const router = useRouter();
const navigate = () => {
  isFundLocked.value || isFundDisabled.value ? null : router.push({ name: 'fund', params: { fundId: props.fund?.id } });
};

</script>
<template>
  <o-fund-card
    :name="fund.name"
    :business-sector-icons="iconsForBusinessSectors"
    :disabled="isFundDisabled"
    :tags="fundCardTags"
    :begining-date="beginingDate"
    :ending-date="endingDate"
    :key-figures="fundCardKeyFigures"
    :image="avatarUrl()"
    @click="navigate()"
  >
    <template
      v-if="isFundLocked"
      #denied
    >
      <p>
        <i18n-t
          v-if="hasToCompleteProfile"
          keypath="funds.card.accessDenied.onGoing.text"
          tag="span"
        >
          <template #link>
            <router-link
              class="text-underline"
              :to="{ name: 'profile-start' }"
              link
              variant="text"
            >
              {{ $t('funds.card.accessDenied.onGoing.link') }}
            </router-link>
          </template>
        </i18n-t>
        <span
          v-if="isProfileUnderReview"
        >
          {{ $t('funds.card.accessDenied.underReview.text') }}
        </span>
      </p>
    </template>
  </o-fund-card>
</template>
