import type { ApiError } from '@opalecapital/api-client';
import { useDialogs } from '@opalecapital/ui/dialogs/composables';
import type { Router } from 'vue-router';
import { useRouter } from 'vue-router';
import { Http401ErrorDialog } from '~/dialogs';

const dialogs = useDialogs({ Http401ErrorDialog });

const catchHttpError = (error: ApiError, { router }: { router: Router }) => {
  switch (error.response?.status) {
    case 404:
      router.push({ name: '404' });
      break;

    case 401:
      dialogs.http401Error.show();
      break;

    default:
      console.error(error);
  }
};

export const useErrors = () => {
  const router = useRouter();
  return {
    catchHttpError: (error: ApiError) => catchHttpError(error, { router })
  };
};
