import type { ApiCredentials, User, WithId } from '@opalecapital/api-client';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useAuth = defineStore(
  'auth',
  () => {
    const user = ref<WithId<User>>();

    const authenticated = computed(() => user.value != undefined);
    const authenticatedUser = computed(() => user.value);
    const credentials = ref<ApiCredentials>();
    const rememberMe = ref(false);

    const login = (
      newUser: WithId<User>,
      newCredentials?: ApiCredentials,
      newRememberMe?: boolean
    ) => {
      user.value = newUser;

      if (newCredentials) {
        credentials.value = { ...newCredentials };
      }

      if (newRememberMe) {
        rememberMe.value = newRememberMe;
      }
    };

    const refresh = (newCredentials: ApiCredentials) => {
      credentials.value = { ...newCredentials };
    };

    const logout = () => {
      user.value = undefined;
      credentials.value = undefined;
      rememberMe.value = false;
    };

    return {
      credentials,
      rememberMe,
      authenticated,
      authenticatedUser,
      login,
      refresh,
      logout
    };
  },
  {
    persist: true
  }
);
