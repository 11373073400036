import { Subscription } from '@opalecapital/api-client';

export const STATUS_COLOR = {
  [Subscription.Status.OnGoing]: 'warning',
  [Subscription.Status.WaitingForValidation]: 'warning',
  [Subscription.Status.Processing]: 'warning',
  [Subscription.Status.Rejected]: 'disabled',
  [Subscription.Status.Sent]: 'info',
  [Subscription.Status.SentForSignature]: 'info',
  [Subscription.Status.Signed]: 'info',
  [Subscription.Status.Cancelled]: 'info',
  [Subscription.Status.InvestorSigned]: 'info',
  [Subscription.Status.Validated]: 'success',
};

export const STEP = {
  AMOUNT: 'amount',
  RISK_CHART: 'risk-chart',
  ENTITY_VALIDATION: 'entity-validation',
  OVERVIEW: 'overview',
} as const;

export const CONFIRMATION_STEP = 'subscription-sent';
