// const showBanner = () => _hsp.push(['showBanner']);
// const revokeCookieConsent = () => _hsp.push(['revokeCookieConsent']);

export const initTags = async () => {
  if (process.env.NODE_ENV === 'production') {
    if (document.getElementById('hs-script-loader')) {
      console.log('HubSpot script already loaded.');
      return;
    }
    console.log('Loading HubSpot Tracking...');
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    script.src = '//js-eu1.hs-scripts.com/26255779.js';

    script.onload = () => {
      console.log('HubSpot Loaded!');
    };

    script.onerror = (error) => {
      console.error('HubSpot: Error!', error);
    };

    document.head.appendChild(script);
  }
};
