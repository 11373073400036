<script lang="ts" setup>
import { useRouter } from 'vue-router';

const router = useRouter();

const emit = defineEmits(['close']);

const goToLoginPage = () => {
  router.replace({ name: 'logout' });
  emit('close');
};
</script>

<template>
  <o-dialog
    :title="$t('_dialogs.http401Error.title')"
    :text="$t('_dialogs.http401Error.text')"
    type="warning"
    persistent
    :primary-btn-text="$t('_dialogs.http401Error.primaryBtnText')"
    @primary-btn-click="goToLoginPage"
  />
</template>
