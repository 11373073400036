import type { Subscription } from '@opalecapital/api-client';
import type { StoreDefinition } from 'pinia';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { SUBSCRIPTION } from '~/constants';

type SubscriptionStep = typeof SUBSCRIPTION.STEP[keyof typeof SUBSCRIPTION.STEP];
type SubscriptionStore = {
  steps: Array<SubscriptionStep>;
  currentStepIndex: number;
  subscription: Partial<Subscription>;
  currentStep: SubscriptionStep | undefined;
  isValidStep: boolean;
};

const steps: Array<SubscriptionStep> = Object.values(SUBSCRIPTION.STEP);

export const useSubscription: StoreDefinition<'subscription', SubscriptionStore> = defineStore(
  'subscription',
  () => {
    const currentStepIndex = ref(0);
    const currentStep = ref<SubscriptionStep>();
    const subscription = ref<Partial<Subscription>>({});
    const isValidStep = ref(false);

    const isLastStep = computed(() => currentStep.value === steps.slice(-1)[0]);
    const previousStep = computed(() => (currentStepIndex.value > 0) ? steps[currentStepIndex.value - 1] : steps[0]);

    const validateStep = (isValid: boolean, payload?: Partial<Subscription>) => {
      isValidStep.value = isValid;

      if (isValid && payload) {
        updateSubscription(payload);
      }
    };

    const reset = () => {
      updateCurrentStepIndex(0);
      validateStep(false);
      subscription.value = {};
    };

    const updateCurrentStepIndex = (stepIndex: number) => {
      currentStepIndex.value = stepIndex;
      currentStep.value = steps[stepIndex];
    };

    const updateSubscription = (payload: Partial<Subscription>) => {
      subscription.value = { ...subscription.value, ...payload };
    };

    return {
      steps,
      currentStepIndex,
      subscription,
      currentStep,
      isValidStep,
      isLastStep,
      previousStep,
      updateCurrentStepIndex,
      updateSubscription,
      reset,
      validateStep,
    };
  }
);
